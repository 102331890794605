/** @jsx jsx */
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { UnidadeFozTabs } from "@/data"
import { jsx } from '@emotion/react'

import {
  chooseUsTitle,
  contentBox,
  btnBox
} from "../assets/styles/FeaturedTab1.styles";

const TurmasTabs = () => {
  const [active, setActive] = useState(0);
  const { posts } = UnidadeFozTabs;
  return (
    <section>
      <div id="tabs">
          <Row>
            <Col lg={12}>
              <ul css={chooseUsTitle}>
                {posts.map(({ title, image }, index) => (
                  <li
                    key={index}
                    className={`${active === index ? "active" : " "}`}
                  >
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setActive(index);
                      }}
                    >
                      <div><img src={image} alt='/' /></div>
                      {title}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <div className="tab-content">
            {posts.map((post, index) => {
              return index === active ? (
                <div
                  className="tab-pane fade show active animated fadeIn"
                  id={`tes_tab_${index}`}
                  key={index}
                >
                  <Row
                    className={`${
                      0 === index % 2 ? " " : "flex-lg-row-reverse"
                    }`}
                  >
                    <Col lg={12}>
                      <div css={contentBox}>
                        <h2>{post.subTitle}</h2>
                        <p>{post.content}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : null;
            })}
          </div>
        </div>
    </section>
  );
};

export default TurmasTabs;
